import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/utils/seo";
import Interactive404 from "../components/pagecomponents/interactive-404";

const NotFoundPage = () => {
  return (
    <Layout>
      <Interactive404 />
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <Seo title="404" />
