import React, { Suspense, useRef } from 'react'
import { Canvas } from "@react-three/fiber"
import { Html, Clone, Environment, useGLTF, Float } from "@react-three/drei"
import * as styles from './interactive-404.module.css';

const SantaHat = () => {

  const hatRef = useRef()
  const model = useGLTF('./../../../assets/santa_hat.glb')

  return (
    <Clone 
      object={model.scene}
      ref={hatRef}
      scale={0.5}
      position={[0, 0.2, 0]}
      rotation={[0, 1.2, 0]}
      dispose={null}
      
    />
  )
}

const Interactive404 = () => {

  return (
    <div className={styles.container}>
      <Canvas gl={{ alpha: false }} camera={{ near: 0.01, far: 110, fov: 30 }}>
        <color attach="background" args={["#FFFFFF"]} />
        <ambientLight intensity={0.6} />
        <Suspense fallback={null}>
          <Environment preset="sunset" />
          
            <Float speed={3}>
            <SantaHat />
            </Float>
          
          <Html as="h1" wrapperClass={styles.textWrapper} center>
              Página no encontrada
          </Html>
          
        </Suspense>
      </Canvas>
    </div>
  )
}

export default Interactive404